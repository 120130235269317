.metadata-wrapper, .edit-document, .document-list, .add-document-container {
    ng2-tag-input {
      border: none;
    }
  }
  
  
  .metadata-wrapper, .edit-document, .document-list, .add-document-container  {
    tag-input .ng2-tag-input {
      border: none;
      &.ng2-tag-input--focused {
        border: none;
      }
    
      .ng2-tag-input.ng2-tag-input--focused {
        border: none
      }
    
      .ng2-tag-input {
        border: none;
      }
      .ng2-tag-input--focused {
        border: none;
      }
    
      .ng2-tags-container {
        tag {
          height: 24px;
          margin: .15rem .3rem .15rem 0;
          delete-icon {
            line-height: 20px;
            height: 20px;
  
            &:hover {
              transform: none;
              -webkit-transform: none;
            }
  
            svg {
              line-height: 20px;
              height: 20px;
            }
          }
        
          .tag-pill {
            height: 24px;
            line-height: 24px;
          }
        }
    
        .ng2-tag-input__text-input {
          height: 24px;
          border-radius: 16px;
          margin-top: .15rem;
        }
      }
    }
  }
  