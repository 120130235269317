@import '../colour-variables.less';

.k-checkbox {
  border-color: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background-color: transparent;
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
  border-width: 2px;
  border-style: solid;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: @teal;
  color: white;
  background-color: @teal;
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  color: @teal;
  border-color: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background-color: transparent;
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
  border-width: 2px;
  border-style: solid;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23297D7D' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M4,8 h8'/%3e%3c/svg%3e");
}

.k-treeview-filter .k-textbox {
  border-color: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background-color: transparent;
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
  border-width: 2px;
  border-style: solid;
}
