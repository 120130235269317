@import '../styles/colour-variables.less';

/* Small common style for all tabs */
.schedNewCommButton {
  outline: none;
  border: 0;
  border-radius: 5px;
  padding: 18px 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  &:not(:last-of-type) {
    margin-bottom: 7px;
  }
}

.tabs-no-pagination{
  .mat-tab-header-pagination {
    display: none !important;
  }
}

.visits-container {
  .column-header {
    padding: 14px 0;
    color: @navy;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 0.5px;
    line-height: 1;
    &-link {
      font-size: 16px;
      font-weight: 700;
      color: #758795;
      text-transform: uppercase;
      margin-top: 20px;
      &:hover {
          cursor: pointer;
      }
    }
  }
    
  .btn {
    outline: none;
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
    .mat-icon {
      width: 32px;
      height: 32px;
      font-size: 32px;
    }
    &--smaller {
      .mat-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }
  }
}
