/* You can add global styles to this file, and also import other style files */
@import '../../node_modules/@progress/kendo-theme-default/dist/all.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '../styles/button-styles.less';
@import '../styles/colour-variables.less';
@import '../styles/form-styles.less';
@import '../styles/panel-styles.less';
@import './custom-overrides/full-calendar-override.less';
@import '../styles/font.less';
@import '../styles/material-override-styles.less';
@import '../styles/tag-input-override-styles.less';
@import './custom-overrides/perfect-scrollbar-override.less';
@import './custom-overrides/material-datepicker-override.less';
@import './custom-overrides/kendo-grid-override.less';
@import './custom-overrides/kendo-chat-override.less';
@import './custom-overrides/kendo-tooltip-override.less';
@import './custom-overrides/kendo-treeview-override.less';
@import '../styles/tabs-styles.less';
@import '../styles/dialog-styles.less';
@import '@angular/cdk/overlay-prebuilt.css';
@import './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';
@import './menu-styles.less';

body {
  min-height: stretch;
  height: 100dvh;
  overflow: hidden;
  font-family: @font-family;
  font-size: 0.75rem;
  color: @black-custom;
}

.line-height-1 {
  line-height: 1;
}

.search-service-code {
  .dropdown-menu {
    max-height: 215px;
    width: 100%;
    overflow-y: auto;
    padding: 0;
    margin: 22px 0 0 0;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: @grey-four;
    }
  }

  .dropdown-item {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
  }
}

.birthday-background {
  background: linear-gradient(238deg, #feffb1, #ffb158, #bc56ff, #ff64c4, #72ecff, #74ffb7);
  background-size: 1200% 1200%;
  -webkit-animation: rainbow 10s ease infinite;
  animation: rainbow 10s ease infinite;
}

.documents-list {
  .dropdown-menu {
    margin: 0;
  }
  .dropdown-item {
    border-radius: @border-radius;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    line-height: 1.25;
    cursor: pointer;
    &:focus {
      color: white !important;
    }
  }
  &:not(.documents-list--chart) {
    .dropdown-menu {
      max-width: 100%;
    }
  }
}

.visits-container {
  .dropdown-menu {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
  }
  .dropdown-item {
    font-size: 16px;
    line-height: 35px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}

ngb-typeahead-window.dropdown-menu {
  height: 375px;
  overflow-y: auto;
}

typeahead-container.dropdown-menu {
  height: auto !important;
  z-index: 1060 !important;
}

.emr-btn-group {
  align-items: center;
  .dropdown-menu {
    padding: 0;
    margin: 0;
  }

  .dropdown-item {
    border-radius: @border-radius;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    cursor: pointer;
    &:focus {
      color: white !important;
    }
  }
  .schedNewCommButton.dropdown-item {
    &:hover {
      background-color: @spring-green;
    }
  }
  .schedNewCommButton.dropdown-main-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .schedNewCommButton.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:after {
      content: none;
    }
  }
}

.bigger {
  font-size: 1rem;
}

.bigger-icon {
  font-size: 1.15rem;
}

.medium {
  font-size: 0.8rem !important;
}

.smaller {
  font-size: 0.6rem !important;
}

.h-10 {
  height: 10% !important;
}

.h-90 {
  height: 90% !important;
}

.light-grey-background {
  background: @grey-one;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

/* Error message displayed when signalr disconnect occurs */
.signalr-disconnect-message {
  height: 30px;
  width: 100vw;
  z-index: 999;
  background: @spring-green;
  color: white;
  position: absolute;
  top: 0;
  left: 0;

  button {
    border: 1px solid white;
    padding: 2px;
    width: 2em;
    height: 2em;
    font-size: 1em;
  }
}

/* Input */

.inline-input {
  height: inherit;
  line-height: inherit;
  font-size: inherit;
  font-family: inherit;
  padding: 0 0.5rem 0 0.5rem;
  border: 0;
  border-radius: @border-radius;
}

.disable-input-number-spinner {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

/* Display and Overlay */

.hidden {
  display: none;
}

.overlay {
  background-color: black;
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0;
  left: 0;
  pointer-events: none;
  margin-left: 55px;
}

#overlayDisableDiv {
  .backdrop {
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  .spinner-three-bounce {
    display: none;
  }
}

#gridDisableDiv {
  .backdrop {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  .spinner-three-bounce {
    display: none;
  }
}

.k-grid-header .k-header > .k-link {
  cursor: default;
}

kendo-popup.kendo-tooltip * {
  background: black;
}
kendo-popup.kendo-tooltip .k-callout {
  background-color: transparent;
  color: black;
}

/* General alignment */
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.align-sub {
  vertical-align: sub;
}

/* Borders */
.border-radius {
  border-radius: @border-radius !important;
}

.white-border-left {
  border-left: 1px solid white;
}

.white-border-right {
  border-right: 1px solid white;
}

.white-border-top {
  border-top: 1px solid white;
}

.grey-six-border-left {
  border-left: 1px solid @grey-six;
}

.grey-six-border-bottom {
  border-bottom: 1px solid @grey-six;
}

.grey-four-border {
  border: 1px solid @grey-four;
}

.grey-four-border-bottom {
  border-bottom: 1px solid @grey-four !important;
}

.grey-four-border-top {
  border-top: 1px solid @grey-four !important;
}

.grey-three-border {
  border: 1px solid @grey-three;
}

.grey-three-border-top {
  border-top: 1px solid @grey-three;
}

.grey-two-border {
  border: 1px solid @grey-two !important;
}

/** General styling **/
.pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

/* Overrides for photo editor */
.ngx-image-editor-component {
  .photo-editor-header > .file-name {
    padding-left: 10px;
  }

  .mat-button.mat-accent,
  .mat-icon-button.mat-accent,
  .mat-stroked-button.mat-accent,
  .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: @navy;
  }

  .mat-raised-button.mat-accent {
    background-color: @navy;
    color: white;
    border-radius: 0;
    margin: 3px;
  }

  .mat-accent .mat-slider-thumb,
  .mat-accent .mat-slider-thumb-label,
  .mat-accent .mat-slider-track-fill,
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: @navy;
  }

  .mat-accent .mat-input-element {
    caret-color: @navy;
  }
}

.mat-sidenav-container,
.mat-sidenav-content,
.mat-tab-body-content {
  transform: none !important;
}

/** Tag Styling **/
.ng2-tags-container {
  tag {
    padding: 0 !important;
  }

  .tag-input {
    background-color: @eggshell;
  }
}

.underline {
  text-decoration: underline !important;
}

.truncate-ellisis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tag-pill {
  padding: 0 0.5rem;
  border-left: 1rem solid #7c9a19;
}

/* Gender dot */
.dot {
  height: 17px;
  width: 16px;
  background-color: @black-custom;
  border-radius: 50%;
  text-align: center;
  color: white;
  display: inline-block;
}

/* Grey background colours */
.grey-one-background {
  background-color: @grey-one;
}

.grey-two-background {
  background-color: @grey-two;
}

.grey-three-background {
  background-color: @grey-three;
}

.grey-four-background {
  background-color: @grey-four;
}

.grey-five-background {
  background-color: @grey-five;
}

.grey-six-background {
  background-color: @grey-six;
}

.grey-seven-background {
  background-color: @grey-seven;
}

/* Coloured backgrounds */

.white-background {
  background-color: white;
}

.teal-background {
  background-color: @teal;
}

.navy-background {
  background-color: @navy;
}

.grey-seven-background {
  background-color: @grey-seven;
}

.grey-three-background {
  background-color: @grey-three;
}

.navy-dark-background {
  background-color: @navy-dark;
}

.blue-light-background {
  background-color: @blue-light;
}

.black-custom-background {
  background-color: @black-custom;
}

.spring-green-light-background {
  background-color: @spring-green-light;
}

.spring-green-background {
  background-color: @spring-green;
}

.calendar-red-background {
  background-color: @calendar-red;
}

.calendar-blue-background {
  background-color: @calendar-blue;
}

/* Font colours */
.white-font {
  color: white;
}

.eggshell-font {
  color: @eggshell !important;
}

.navy-font {
  color: @navy;
}

.teal-font {
  color: @teal;
}

.pastel-red-font {
  color: @pastel-red;
}

.calendar-red-font {
  color: @calendar-red;
}

.purple-font {
  color: @purple;
}

.calendar-blue-font {
  color: @calendar-blue;
}

.calendar-green-font {
  color: @calendar-green;
}

.spring-green-font {
  color: @spring-green;
}

.grey-one-font {
  color: @grey-one;
}

.grey-two-font {
  color: @grey-two;
}

.grey-three-font {
  color: @grey-three;
}

.grey-four-font {
  color: @grey-four;
}

.grey-five-font {
  color: @grey-five;
}

.grey-six-font {
  color: @grey-six;
}

.invalid,
.required {
  color: @pastel-red;
}

/* Font Styles */

.sub-heading {
  text-transform: uppercase;
  font-size: 14px;
}

.uppercase {
  text-transform: uppercase;
}

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    border: inherit;
    outline: inherit;
    box-shadow: inherit;
    padding: inherit;
    vertical-align: inherit;
  }
}

/* Global Material Overrides */
.mat-form-field {
  font-family: @font-family;
  color: @black-custom;

  .mat-form-field-label {
    color: @teal;
  }

  &.document-list-textarea {
    .mat-form-field-label {
      color: @black-custom;
    }
  }

  &.apply-text-field {
    .mat-form-field-underline {
      background-color: rgba(black, 0.1);
    }
    .mat-form-field-label {
      color: rgba(black, 0.3);
    }
    .mat-select-arrow {
      color: @navy;
    }
    .mat-option-text {
      color: black;
    }
  }
}

.mat-form-field.mat-focused {
  .mat-form-field-label {
    color: @teal;
  }
  &.document-list-textarea {
    .mat-form-field-label {
      color: @teal;
    }
  }
  &.apply-text-field {
    .mat-select-arrow {
      color: @navy;
    }
  }
}

.no-underline .mat-form-field-underline {
  display: none !important;
}

.mat-form-field-ripple {
  background-color: @teal !important;
}

/* Opacity classes */
.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

/* Height */
.h-85 {
  height: 85%;
}

.h-33 {
  height: 33.3333%;
}

.h-66 {
  height: 66.6666%;
}

.w-5 {
  width: 5%;
}

.minh-0 {
  min-height: 0;
}

.flex-1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

/* Z-Index */
// Overlay -> z-index: 3
// Disabled background -> z-index: 2
.z-index-3 {
  z-index: 3;
}

/* Menu Icons */
.menu-icon {
  font-size: 20px;
  width: 100%;
  text-align: center;
}

.menu-icon-expanded {
  margin-right: 10px;
  width: 25px;
}

/* Override Kendo Multi-Select */
.k-list .k-item.k-state-selected {
  background-color: @spring-green !important;
}

/* Patient Chart perfect-scrollbar overrides */
.scrollbar-container .ps .ps-content {
  height: 100% !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.document-preview {
  .formio-iframe {
    height: calc(100dvh - 60px) !important;
  }
}

.header-line {
  background-color: @teal;
  height: 10px;
  margin-top: 1px;
  flex-shrink: 0;
}

.success-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: @spring-green;
  color: white;
  font-weight: bold;
  z-index: 9;
  transition: height 0.5s;
}

.cdk-overlay-container {
  z-index: 1060 !important;
}

.draw-tool-modal {
  .modal-dialog {
    max-width: unset;
    width: fit-content;
  }

  .modal-content {
    width: fit-content;
    border-radius: @border-radius;
    border: none;
  }
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  // Update this to match the colour of the selected date in the calendar
  // when calendar is in range mode
  background: @spring-green;
}

.chat-settings-menu {
  min-height: unset !important;
}

// ngb-modal-window{
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 1051;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   outline: 0;

//   div[role="document"]{
//     display: flex;
//     align-items: center;
//     position: relative;
//     width: auto;
//     pointer-events: none;
//   }
// }
// ngb-modal-backdrop{
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 1040;
//   width: 100vw;
//   height: 100dvh;
//   background-color: #000;
//   opacity: .5;
// }
