.add-product-dialog .mat-dialog-container {
  padding: 0;
  margin: 40px 0;
}

.cdk-global-overlay-wrapper:has(.add-product-dialog) {
  overflow-y: auto;
}

.select-products-dialog {
  width: 620px;
}
