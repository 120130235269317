/** Override for appointments component*/
app-appointments {
    .ps__rail-x {
        z-index: 1;
    }
    .ps--focus>.ps__rail-x, 
    .ps--scrolling-x>.ps__rail-x,
    .ps:hover>.ps__rail-x {
        opacity: 1;
    }
}
