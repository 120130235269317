.em-tooltip * {  
    background: black;
  }
  .em-tooltip .k-callout {
    background-color: transparent;
    color: black;
  }

  .k-grid .k-tooltip.k-tooltip-validation {
    display: inline-block;
  }

  .k-tooltip-validation>.k-icon {
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 2px;
}