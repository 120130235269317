/*default version*/
@font-face {
	font-family: 'Avenir';
	src: 
		local('Avenir'),
		local('AvenirLTStd-Medium'),
		url('fonts/AvenirLTStd-Medium.otf') 
		format('opentype');
}
/*bold version*/
@font-face {
	font-family: 'Avenir';
	src: 
		local('Avenir'),
		local('AvenirLTStd-Heavy'),
		url('fonts/AvenirLTStd-Heavy.otf') 
		format('opentype');
	font-weight: bold;
}
/*light version*/
@font-face {
	font-family: 'Avenir';
	src: 
		local('Avenir'),
		local('AvenirLTStd-Light'),
		url('fonts/AvenirLTStd-Light.otf') 
		format('opentype');
	font-weight: lighter;
}