@import '../colour-variables.less';

.emr-kendo-grid {
  .k-action-buttons {
    .k-button {
      &:active {
        color: grey;
        background: transparent;
      }
    }
    .k-primary {
      color: grey;

      &:active {
        color: grey;
        background: transparent;
      }

      &:hover {
        color: grey;
        background: transparent;
      }
    }
  }

  &.k-grid { 
    th {
      color: white;
      font-size: 14px;
      font-weight: 400;
      border: 0;
    }
    td {
      color: @navy;
    }
    th,
    td {
      &:not(.k-hierarchy-cell[ng-reflect-logical-col-index='0']) {
        padding: 10px 20px;
      }
      letter-spacing: 0.5px;
    }
    tr {
      &.k-alt {
        background-color: @grey-seven;
        &:hover {
          background-color: @grey-seven;
        }
      }
    }
  }

  .k-grid-header {
    background-color: @teal;

    .k-grid-filter-menu {
      color: white;
      background-color: transparent !important;
      &:hover {
        color: white;
        background-color: transparent !important;
      }
      &.k-active {
        background-color: transparent !important;
        color: grey;
      }
    }

    .k-table-thead {
      background-color: unset;
    }
    .text-right .k-cell-inner > .k-link {
      justify-content: right;
    }
    .k-sort-icon {
      color: white;
    }
  }

  .k-pager-numbers {
    .k-link {
      color: @teal;
      &:hover {
        color: @teal;
        background-color: transparent;
      }
    }
    .k-state-selected {
      color: white;
      background-color: @teal;
      &:hover {
        color: white;
        background-color: @teal;
      }
    }
  }

  .k-pager-nav {
    &:hover {
      color: @teal;
      background-color: transparent;
    }
  }

  .underline {
    text-decoration: underline;
  }

  // Optional Classes
  &.communications {
    td {
      font-size: 0.875rem !important;
    }
    th {
      font-size: 1rem;
    }
  }

  &.large-header {
    .k-header.k-table-th {
      height: 50px;
      vertical-align: middle;
    }
  }

  &.hide-default-sort {
    .k-sort-order,
    .k-sort-icon {
      display: none !important;
    }
    .k-grid-header {
      .k-cell-inner > .k-link > i {
        margin-left: 6px;
      }
    }
  }
}

// Universal Grid Overrides
.k-grid .k-command-cell .k-button {
    background: none !important;
    border: none !important;
}

app-multicheck-filter {
  input:checked.k-checkbox {
    border-color: @spring-green;
    background-color: @spring-green;
  }
}

kendo-grid-filter-menu-container .k-actions .k-button-solid-primary {
  border-color: @spring-green;
  background-color: @spring-green;
}

kendo-grid-toolbar {
  .k-button {
    background-color: @spring-green;
  }
}

// Nudges section
.nudge-changes {
  & > div {
    border-bottom: 1px solid grey;
    padding: 10px 0;
    margin-bottom: 8px;
  }
}

.nudge-modification,
.user-nudge-detail {
  span.arrow {
    margin: 0 5px;

    &::before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      content: '\f178';
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
    }
  }

  .username {
    font-weight: bold;
    padding: 0 5px;
  }

  .timestamp {
    padding: 0 5px;
  }

  .nudge-modification-header {
    margin-bottom: 8px;
  }

  .user-nudge-detail-signature {
    .username {
      font-weight: bold;
    }
  }

  .field-name {
    color: @teal;
  }

  .nudge-modification-before-and-after {
    .field-value.old {
      color: @calendar-red;
    }

    .field-value.new {
      color: teal;
    }
  }
}

// Datepicker Overides
.communication-timepicker {
  &.k-timepicker {
    .k-picker-wrap {
      border: 0;
      border-bottom: 2px solid rgba(black, 0.08);
    }
    .k-state-focused {
      box-shadow: none;
    }
    .k-select {
      background: transparent;
      color: @navy;
    }
    .k-input {
      padding: 0px;
      height: 20px;
    }
  }
}

.emr-datepicker {
  &.k-datepicker {
    .k-picker-wrap {
      border: 0;
      border-bottom: 1px solid rgba(black, 0.08);
      border-radius: 0;
    }
    .k-state-focused {
      box-shadow: none;
    }
    .k-select {
      background: transparent;
      color: @navy;
    }
  }
}
