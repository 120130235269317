@import '../../styles/colour-variables.less';
@import '../../styles/style-variables.less';

full-calendar {
  // toolbar header
  .fc-toolbar.fc-header-toolbar {
    display: none;
  }

  .fc-view {
    thead .fc-scroller {
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      // /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    tbody > tr.fc-scrollgrid-section-body > td {
      &:nth-child(1) {
        border-right: none;
        .fc-scroller {
          margin-bottom: -25px;
          padding-bottom: 25px;
          overflow: hidden !important;
        }
      }
      // &:nth-child(2) .fc-scroller {
      /* Hide scrollbar for Chrome, Safari and Opera */
      // &::-webkit-scrollbar:vertical {
      //   display: none;
      // }

      /* Hide scrollbar for IE, Edge and Firefox */
      // -ms-overflow-style-y: none; /* IE and Edge */
      // scrollbar-width: none; /* Firefox */
      // }
    }

    th {
      padding: 10px 0;
    }

    // resource
    .fc-scrollgrid-section-header > th {
      padding: 0;
    }

    .fc-resource {
      background: #ffffff !important;
    }

    .fc-col-header-cell.fc-resource {
      cursor: pointer;
      text-transform: uppercase;
      background-color: @grey-four !important;
    }

    .fc-resourceTimeGridWeek-view {
      .fc-col-header-cell.fc-resource {
        cursor: default;
      }
    }

    // slots
    .fc-timegrid-slot {
      border-style: solid;
      border-left: 0;
      border-right: 0;
      height: 3.5em;

      &:not(.fc-timegrid-slot-minor) {
        border-top-color: rgb(100, 100, 100);

        .fc-axis.fc-time {
          border-width: 1px;
          border-bottom: 0;
        }
      }

      &.fc-timegrid-slot-minor {
        border-left: 0;
      }

      &.fc-timegrid-slot-label {
        &.fc-timegrid-slot-minor {
          border: none;
        }
        div {
          color: @teal;
          text-align: center;
          font-weight: bold;
          border-width: 0;
        }
      }
    }

    .fc-timegrid-axis {
      padding: 0;
    }

    .fc-timegrid-axis,
    .fc-timegrid-slot-label {
      width: 60px !important;
    }

    .fc-timegrid-now-indicator-line {
      display: none;
    }

    .fc-timegrid-now-indicator-arrow {
      --fc-now-indicator-color: @spring-green;
    }

    // events
    .fc-bg-event {
      opacity: 1;
    }

    .fc-event {
      border: none;
      border-radius: @border-radius-bigger;
      margin: 0;
      background: none;
    }

    .users-btn {
      height: 42px !important;
      display: flex;
      justify-content: center;
      background: @grey-four;
      font-size: 150%;
      cursor: pointer;
    }
  }
}
