@import '../styles/colour-variables.less';

.nav-sub-menu {
  font-size: 0.875rem;
  width: 180px;
  ul {
    margin: 0;
    padding: 0;
    a {
      color: @black-custom;
      font-size: 16px;
      &:hover {
        text-decoration: none;
      }
    }
    .sub-menu-item {
      cursor: pointer;
      &.active,
      &:hover {
        background-color: @spring-green;
      }
    }
  }
}
