@import '../styles/colour-variables.less';
@import "../styles/style-variables.less";

/* Global Buttons */
.btn {
  border-radius: @border-radius;
  cursor: pointer;
}

.btn:disabled {
  cursor: default;
}

.btn-clean {
  outline: none;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  &:focus {
    outline: none;
  }
}

.btn-small {
  font-size: 0.5rem;
  padding: .375rem .75rem .175rem;
  vertical-align: baseline;
  margin: .375rem .75rem;
  letter-spacing: 0.5px;
}

.btn-medium {
  font-size: 0.75rem;
}

.btn-blue {
  color: @calendar-blue !important;
}

.btn-red {
  color: @calendar-red !important;
}

.btn-green {
  color: @calendar-green !important;
}

.btn-grey-two {
  background-color: @grey-two;
}

.patient-btn {
  color: @grey-six;
  i {
    font-weight: 400 !important;
  }
}
.btn-treatment {
  top: 0;
  position: absolute;
  right: 0;
}

.hover-blue:hover {
  color: @calendar-blue;
}

.hover-red:hover {
  color: @calendar-red;
}

.hover-green:hover {
  color: @calendar-green;
}

.em-btn {
  text-transform: uppercase;
  color: white;
  border-radius: @border-radius;
  &:hover {
    color: white;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.em-btn-green {
  background-color: @spring-green;
  border-radius: 4px;
}
.em-btn-teal {
  background-color: @teal;
}

.em-btn-red {
  background-color: @calendar-red;
}

.em-btn-blue {
  background-color: @navy;
}

.em-btn-black-text {
  color: @black-custom;
}

.em-btn-nav-tab {
  background-color: @grey-six;
  &.selected {
    background-color: @spring-green;
  }
}

/* Tab/Panel Management Buttons */
.re-links {
  background: @teal;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  left: -100%;
  top: 0%;

  a {
    color: #fff;
  }

  a i {
    color: #fff;
    font-size: 16px;
  }

  hr {
    background: #fff;
    width: 30px;
    margin: 0;
  }
}

/* Toggle button */
.switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 14px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: @grey-six;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: @teal;
}

input:focus + .slider {
  box-shadow: 0 0 1px @teal;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 14px;
}

.slider.round:before {
  border-radius: 50%;
}

.service-note-btn {
  padding: 0.5rem 0.25rem;
}

/* Radio buttons */
.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: @teal;
  background-color: @teal;
}