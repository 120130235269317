@import '../styles/colour-variables.less';
@import '../styles/style-variables.less';

.panel-container {
  padding: 25px;

  .panel-item-border {
    border: 1px solid @grey-three;
  }

  h5,
  h6 {
    color: @navy;
  }

  h5 {
    font-size: 1.05rem;
    font-weight: bold;
  }

  label {
    color: @teal;
    font-size: 0.875rem;
  }

  form {
    label {
      color: @teal;
      font-size: 0.75rem;
    }

    .form-control {
      border: 0;
      border-bottom: 1px solid @grey-two;
      padding: 0;
      width: auto;
      height: auto;
      border-radius: 0;
      font-size: 0.9rem;

      option {
        background-color: @grey-two;
        border: none;
      }

      option:hover,
      option:checked {
        background-color: @spring-green;
        color: white;
      }
    }

    .form-control.small {
      font-size: 0.75rem;
    }
  }

  .dropdown-menu {
    border: 1px solid @grey-three;
    margin-top: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 100%;
  }
  .dropdown-item {
    font-size: 14px;
    line-height: 1.25;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #16181b;
    background-color: #f8f9fa;
  }

  .negative-row-margin {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.panel-container .service-chart label {
  font-size: 16px !important;
}

.service-chart .mat-form-field-label-wrapper {
  top: -1.8em;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  margin-top: 10px;
}

app-edit-retention-campaign .mat-form-field-appearance-legacy .mat-form-field-infix {
  margin-top: 0;
}

app-edit-retention-campaign .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1em;
}

app-edit-retention-campaign .visit-history-panel .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  margin-bottom: 0;
}

app-edit-retention-campaign .service-product-panel .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  margin-bottom: 0;
}

.panel-box {
  background-color: @grey-two;
  padding: 10px;
  color: @navy;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  .btn {
    &:focus {
      box-shadow: none;
    }
  }
  &-button {
    width: 65px;
    height: 100%;
    color: @navy;
    font-size: 16px;
    background-color: @grey-four;
    border: 0;
    outline: none;
    font-weight: 600;
    text-transform: uppercase;
    flex-shrink: 0;
    &:focus,
    &.active {
      outline: none;
      box-shadow: none;
    }
    &.em-btn-green {
      color: white;
      background-color: @spring-green;
    }
    &.em-btn-red {
      color: white;
      background-color: @calendar-red;
    }
    &-icon {
      background-color: transparent;
      border: 0;
      outline: none;
      padding: 0;
      color: @navy;
      font-size: 16px;
      &:focus,
      &.active {
        outline: none;
        box-shadow: none;
      }
    }
  }
  &-column {
    &:not(:last-of-type) {
      margin-right: 15px;
    }
    &--main {
      flex-grow: 1;
    }
    &.danger {
      color: @calendar-red;
    }
  }
  &-content {
    padding-bottom: 10px;
    border-bottom: 1px solid @grey-four;
    .content-row {
      font-size: 12px;
      display: grid;
      grid-template-columns: repeat(4, minmax(50px, 1fr));
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }
  .date {
    margin-top: 5px;
    font-size: 12px;
    color: @black-custom;
    font-weight: 600;
  }
  .lighter {
    opacity: 0.4;
  }
  &--small {
    font-size: 12px;
  }
  .color-sign {
    width: 5px;
    height: calc(100% + 28px);
    background-color: green;
    margin-top: -8px;
  }
}

.payment-form {
  .mat-form-field,
  .mat-form-field-infix {
    width: 100%;
    font-weight: 500;
  }

  .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
  .mat-select-arrow {
    color: @navy;
  }
  .mat-form-field {
    .mat-form-field-ripple {
      background-color: @navy !important;
    }
  }
}

.service-chart-input {
  padding: 0;
  background-color: white;
  border: 0;
  box-shadow: none;
  outline: none;
  transition: 300ms ease;
  transition-property: backround-color, opacity, border-bottom, border-color;
  resize: none;
  line-height: 1.15;
  box-sizing: content-box;
  &:not(:read-only):focus {
    box-shadow: none;
  }

  &::placeholder {
    opacity: 0.5;
  }
}

.treatment-notes-input {
  padding: 5px;
}
