@import './colour-variables.less';
@import './style-variables.less';
.cdk-global-scrollblock {
  overflow-y: auto;
}

.mat-dialog-title {
  font-family: @font-family;
}

.patient-note .mat-tab-group {
  .mat-tab-label {
    text-transform: none;
  }
}

.mat-tab-group {
  .mat-tab-label {
    color: white;
    border-bottom: none;
    background-color: @teal;
    opacity: 1 !important;
    min-width: auto;
    height: auto;
    padding: 5px 10px;
    margin-right: 5px;
    text-transform: uppercase;
  }

  .mat-tab-label-active {
    background-color: @spring-green;
  }

  .mat-tab-header,
  .mat-tab-nav-bar {
    border: none;
    padding-right: 35px;
  }
  .mat-tab-header-pagination {
    box-shadow: none;
    min-width: 24px;
  }
}

.mat-tab-link {
  font-size: 16px;
}

/* Underline bar on tabs */
.mat-ink-bar {
  background-color: @spring-green !important;
  display: none;
}

/* Checkbox background colour*/
.mat-checkbox-checked.mat-accent:not(.mat-checkbox-disabled) .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent:not(.mat-checkbox-disabled) .mat-checkbox-background {
  background-color: @teal;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: @teal;
}

.reports-checkbox {
  label.mat-checkbox-layout {
    margin-bottom: 0;
  }
}

/* Calendar Styling */
.calnav {
  .mat-calendar-header {
    display: none;
  }

  app-calnav-noheader .mat-calendar-table-header {
    display: none;
  }
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: @spring-green;
}

.mat-calendar-body-selected {
  background-color: @spring-green;
}

.mat-calendar-table-header {
  color: @teal;
}

.mat-calendar-body-label {
  color: @teal;
  font-weight: bold;
}

.mat-calendar-body-cell {
  font-size: 10px;
}

.mat-calendar-body-today {
  border-color: @spring-green !important;
}

.lightbox-dialog {
  .mat-dialog-container {
    background: rgba(0, 0, 0, 0.87);
    overflow-y: hidden;
    padding: 0;
  }
}

.prescription-signature-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.patient-warning-box {
  mat-dialog-container {
    background: red;
    color: white;
    h1 {
      font-size: 0.75rem;
    }
    p {
      font-size: 20px;
    }
  }
}

.allergy-dialog {
  .mat-dialog-container {
    background: @grey-four;
  }
}

app-edit-review-settings {
  mat-label.label-header {
    font-size: 14px;
    font-weight: 700;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    color: #1c344e;
  }
}

.mat-radio-container,
.mat-radio-outer-circle,
.mat-radio-inner-circle {
  width: 15px;
  height: 15px;
}

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.7);
}

.mat-radio-button .mat-radio-ripple {
  background-color: transparent;
}

.mat-radio-outer-circle {
  border-width: 1px;
}

.mat-radio-button.mat-accent {
  .mat-radio-inner-circle {
    background-color: @spring-green;
  }

  .mat-ripple-element {
    background-color: rgba(@spring-green, 0.5) !important;
  }

  &.mat-radio-checked {
    .mat-radio-persistent-ripple {
      background-color: @spring-green;
    }
    .mat-radio-outer-circle {
      border-color: @spring-green;
    }
  }

  &.confirmation-radio-button {
    .mat-radio-inner-circle {
      background-color: @teal;
    }
    .mat-ripple-element {
      background-color: rgba(@teal, 0.5) !important;
    }
    &.mat-radio-checked {
      .mat-radio-persistent-ripple {
        background-color: @teal;
      }
      .mat-radio-outer-circle {
        border-color: @teal;
      }
    }
  }
}

// toggle button
.mat-slide-toggle-content {
  order: -1;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 700;
  color: @navy;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(@spring-green, 0.5);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background: @spring-green;
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: @spring-green;
}

// reset .mat-accordion according to design
.mat-accordion {
  .btn {
    &:focus {
      box-shadow: none;
    }
  }
  .mat-expansion-panel-header-title {
    margin-right: 0;
  }

  .mat-expansion-panel {
    border-radius: 0;
    box-shadow: none;
    &:first-of-type,
    &:last-of-type {
      border-radius: 0 !important;
    }
    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
      border: 0;
    }
    &:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
      background: transparent;
    }
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  .mat-expansion-panel-header {
    padding-left: 15px;
    padding-right: 15px;
  }

  &,
  .mat-expansion-panel-header,
  .mat-expansion-panel-content {
    font-family: @font-family;
  }

  .mat-expansion-panel-body {
    padding: 0 15px;
    .mat-expansion-panel-header-title {
      background: @grey-two;
    }
    .mat-expansion-panel-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .mat-expansion-indicator {
    &::after {
      display: none;
      visibility: hidden;
    }
  }

  &.accrd-visit-treatment {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  &.product-purchase-history {
    .mat-expansion-panel-body {
      padding-bottom: 15px;
    }
  }

  &.patient-invoice-history,
  &.service-charts {
    .mat-expansion-panel {
      margin-bottom: 7px;
    }
  }
  &.service-charts {
    .mat-expansion-panel-header-title {
      min-height: 67px;
    }
    .mat-expansion-panel {
      position: relative;
      .mat-expansion-panel-header {
        &.cdk-program-focused {
          background: transparent;
        }
      }
    }
    .mat-content {
      height: 100%;
    }
  }
  .patient-invoice-history {
    .mat-expansion-panel-header-title {
      min-height: 67px;
    }
    .mat-content {
      height: 100%;
    }
  }
  &.prescriptions-widget {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
  &.treatments-list,
  &.treatment-plan,
  &.prescriptions-widget {
    .mat-expansion-panel {
      .mat-expansion-panel {
        margin: 0 15px;
        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
      }
    }
    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  &.appointments-list {
    .mat-expansion-panel-content {
      border: 1px solid @grey-four;
    }
  }
}

.nugdes-select {
  border-top: 4px solid @spring-green;

  &.mat-select-panel .mat-option {
    display: flex;
    flex-flow: row-reverse;
    border-bottom: 1px solid @grey-four;

    .mat-pseudo-checkbox {
      color: @black-custom;
      border: 1px solid;
      width: 14px;
      height: 14px;
    }

    .mat-option-pseudo-checkbox {
      margin-right: 0;
    }

    .mat-option-text {
      color: @black-custom;
    }

    .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
      background: transparent;
      border-color: @spring-green;
    }
    .mat-pseudo-checkbox::after {
      top: 3px;
      left: 3px;
      width: 6px;
      height: 3px;
      opacity: 1;
      box-sizing: content-box;
      position: absolute;
      content: '';
      border-bottom: 1px solid currentColor;
      -webkit-transition: opacity 90ms cubic-bezier(0, 0, 0.2, 0.1);
      transition: opacity 90ms cubic-bezier(0, 0, 0.2, 0.1);
      color: @black-custom;
    }

    .mat-pseudo-checkbox-checked::after {
      color: @spring-green;
      border-left: 1px solid currentColor;
    }
  }
}

.mat-calendar-cell-semi-selected {
  background-color: @spring-green-light;
}

.mat-calendar-body-begin-range {
  border-radius: 100% 0 0 100%;
  background-color: @spring-green-light;
}

.mat-calendar-body-end-range {
  border-radius: 0 100% 100% 0;
  background-color: @spring-green-light;
}

.mat-select {
  &.mat-select-disabled {
    .mat-select-placeholder {
      color: rgba(0, 0, 0, 0.38);
    }
  }
}

.mat-form-field {
  &.mat-form-field-disabled {
    .mat-form-field-label {
      color: rgba(0, 0, 0, 0.38);
    }
  }
}

.mat-form-field-underline {
  margin-bottom: 4px;
}

.add-allergy {
  .mat-form-field,
  .mat-form-field-infix {
    width: 100%;
  }
}

.w-auto {
  .mat-form-field-infix {
    width: auto;
  }
}

.treatment-plan-col {
  .treatment-plan {
    > .mat-expansion-panel {
      height: 100%;

      .mat-expansion-panel-content {
        height: 100%;

        > .mat-expansion-panel-body {
          height: calc(100% - 48px);
        }
      }
    }
  }
}

.padding-0-field {
  .mat-form-field-infix {
    border-top: 0;
  }
}

.billing-modal-wrapper {
  .mat-form-field-infix {
    width: auto;
  }
}

app-management {
  .mat-tab-label-active {
    opacity: 1;
  }
}

.document-section {
  .mat-tab-header,
  .mat-tab-nav-bar {
    border: none;
    padding: 10px 43px 10px 15px;
  }
}

.document-view-modal {
  .mat-dialog-container {
    padding: 0;
    .document-container {
      div[ref='iframeContainer'] {
        padding: 10px 10px 0px;
      }
      .formio-form-pdf .btn {
        z-index: 99;
      }
    }
  }
}

.document-preview-modal {
  width: 100%;
  .mat-dialog-container {
    width: 100%;
    height: 90dvh;
    padding: 0;
    overflow-y: hidden;
    .document-container {
      padding: 10px 0;
      position: relative;
      .formio-iframe {
        height: calc(90dvh - 81px) !important;
      }
      div[ref='iframeContainer'] {
        padding: 10px 10px 0px;
      }
      .formio-form-pdf .btn {
        z-index: 99;
      }
    }
  }
}

.custom-dialog-container {
  .mat-dialog-container {
    padding: 0;
    border-radius: @border-radius;
  }
}

.mat-accordion {
  .nudge-history {
    .mat-expansion-panel-header {
      font-size: 12px;
    }
    .mat-expansion-panel-body {
      font-size: 12px;
      padding: 0;
    }
  }
}

.sender-type {
  .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
  .mat-select-arrow {
    color: @navy;
  }
  &.mat-form-field {
    .mat-select-value-text {
      color: @navy;
    }
    .mat-form-field-ripple {
      background-color: transparent !important;
    }
    .mat-form-field-underline {
      bottom: 14.5px;
      background-color: rgba(0, 0, 0, 0.08) !important;
    }
  }
}

app-edit-retention-campaign {
  .k-picker-wrap .k-dateinput .k-dateinput-wrap,
  .emr-datepicker.k-datepicker .k-select {
    background: @grey-one;
  }
  .input-number {
    font-size: 14px;
  }
  .mat-radio-label {
    margin-bottom: 0;
  }
  .mat-form-field-infix {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mat-form-field-underline {
    height: 0px !important;
  }
  .sender-type {
    .mat-form-field-infix {
      padding-top: 6px;
      padding-bottom: 0;
    }
    &.mat-form-field {
      .mat-form-field-underline {
        bottom: 13.5px;
      }
    }
  }
}

app-management {
  button[aria-label='Clear'] {
    outline: none;
    .mat-button-focus-overlay {
      background: transparent;
    }
    .mat-button-ripple {
      opacity: 0;
    }
  }
}

.mat-sidenav-container,
.mat-sidenav-content,
.mat-tab-body-content {
  transform: none !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 30px !important;
}

.mat-input-element {
  font-size: 0.875rem !important;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(1);
}

.mat-form-field-can-float
  .mat-input-server[label]:not(:label-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.34374em) scale(1);
}

.mat-form-field-subscript-wrapper {
  font-size: 100%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(1);
}

.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-form-field-autofill-control:-webkit-autofill
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(1);
}

.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server[label]:not(:label-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(1);
}

.mat-menu-panel {
  button:focus {
    outline: none;
  }
}

.mat-dropdown-80vh {
  max-height: 80vh !important;
}

.mat-dropdown-52vh {
  max-height: 52vh !important;
}
